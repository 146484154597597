<template>
  <div class="audits">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm"
      :rowHover="true"
      :value="audits"
      :loading="isLoading"
      editMode="cell"
      :lazy="true"
      filterDisplay="row"
      :stateKey="tableStateStore.filterName()"
      :filters.sync="tableStateStore.filters"
      :rows.sync="tableStateStore.pagination.pageSize"
      :sortField="tableStateStore.sorting.sortField"
      :sortOrder="tableStateStore.sorting.sortOrder"
      :first="tableStateStore.skip()"
      stateStorage="local"
      :totalRecords="getAuditCount"
      :paginator="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :expandedRows.sync="expandedRows"
      @row-expand="onRowExpand"
      @row-collapse="onRowCollapse"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <div class="mt-2">
              {{ getAuditCount }}
              {{ $tc('audit_pluralized', getAuditCount) }}
            </div>
          </div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
            <div class="button-right">
              <Button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="expandAll">
                Alle öffnen
              </Button>
              <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="collapseAll">
                Alle schliessen
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column :expander="true" :headerStyle="'width: 30px'" />

      <Column
        field="createdAt"
        :header="$t('date')"
        headerClass="center"
        className="center"
        sortable
        filterField="dateRange"
      >
        <template #body="slotProps">
          {{ $datetime(slotProps.data.createdAt) }}
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            ref="appointmentCapacityCalendar"
            @input="filterCallback()"
            v-model="filterModel.range"
            selectionMode="range"
            class="p-column-filter"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="action"
        :header="$t('action')"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          {{ slotProps.data.action }}
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="user" :header="$t('users')" headerClass="center" className="center" sortable>
        <template #body="slotProps">
          {{ slotProps.data.user.firstname }} {{ slotProps.data.user.lastname }} ({{
            slotProps.data.user.email
          }})
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="resourceType"
        :header="$t('resourceType')"
        headerClass="center"
        className="center"
        :showFilterMenu="false"
        sortable
      >
        <template #body="slotProps">
          {{ $t(slotProps.data.resourceType) }}
        </template>

        <template #filter>
          <!-- <MultiSelect
            :value="tableStateStore.customFilters.resourceType"
            :options="resourceTypes"
            @input="onResourceTypeFilter"
            optionLabel="label"
            :placeholder="$t('resourceType')"
            display="chip"
            :style="{ 'max-width': '180px' }"
          /> -->

          <Dropdown
            v-model="tableStateStore.customFilters.resourceType.value"
            :options="getEnumValues('ResourceType')"
            @input="onResourceTypeFilter"
            :placeholder="$t('select')"
            class="p-column-filter"
            :showClear="true"
          >
            <template #option="slotProps">
              {{ $t(slotProps.option) }}
            </template>
            <template #value="slotProps">
              {{ $t(slotProps.value) }}
            </template>
          </Dropdown>
        </template>
      </Column>

      <template #expansion="slotProps">
        <!-- <div class="expansion grid"> -->
        <div v-if="slotProps.data.payload">
          <pre>{{ slotProps.data.payload }}</pre>
        </div>
        <div v-if="slotProps.data.fieldPath">
          <pre>fieldPath: {{ slotProps.data.fieldPath }}</pre>
          <pre>newValue: {{ slotProps.data.newValue }}</pre>
          <pre>oldValue: {{ slotProps.data.oldValue }}</pre>
        </div>
        <div v-else><pre>N/A</pre></div>
        <!-- </div> -->
      </template>
    </DataTable>
  </div>
</template>
>
<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Breadcrumbs from '@/components/Breadcrumbs';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';

export default {
  components: {
    Breadcrumbs,
    DataTable,
    Column,
    InputText,
    Calendar,
    Dropdown,
    // MultiSelect,
  },
  data() {
    return {
      expandedRows: [],

      auditTableStateConfig: {
        name: 'audits',
        customFilters: {
          resourceType: { value: null },
        },
        filters: {
          action: { value: null },
          user: { value: null },
          dateRange: {
            range: [
              this.$dayjs()
                .subtract(14, 'days')
                .toDate(),
            ],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getAudits', 'getAuditCount', 'getTableState', 'getEnumValues']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('audit_log') }];
    },
    tableStateStore() {
      return this.getTableState(this.auditTableStateConfig.name);
    },
    audits() {
      return this.getAudits;
    },
    resourceTypes() {
      const resourceTypes = this.getEnumValues('ResourceType').map((resourceType) => {
        return { value: resourceType, label: this.$t(resourceType) };
      });
      return resourceTypes;
    },
  },
  methods: {
    ...mapActions(['fetchAudits', 'initTableState', 'fetchEnumValues']),

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchAudits({
        pagination: {
          skip: this.tableStateStore.skip(),
          pageSize: this.tableStateStore.pagination.pageSize,
        },
        sorting: this.tableStateStore.sorting,
        filters: { ...this.tableStateStore.filters, ...this.tableStateStore.customFilters },
      });
    },

    /**
     * Filter by resourceType
     * @param value
     */
    async onResourceTypeFilter(event) {
      if (this.tableStateStore.customFilters.resourceType) {
        this.tableStateStore.customFilters.resourceType.value = event;
      }
      await this.loadTableData();
    },

    onRowExpand(event) {},
    onRowCollapse(event) {},
    expandAll() {
      this.expandedRows = this.audits.filter((d) => d._id);
    },
    collapseAll() {
      this.expandedRows = null;
    },

    /**
     * send data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, value, field } = event;
      // console.log('onCellEditComplete():', event);
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableStateStore.pagination = { page: event.page, pageSize: event.rows };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableStateStore.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      // force dateRange end date to end of day
      if (
        this.tableStateStore.filters.dateRange.range &&
        this.tableStateStore.filters.dateRange.range[1]
      ) {
        // Set end date to the end of the day
        this.tableStateStore.filters.dateRange.range[1] = this.$dayjs(
          this.tableStateStore.filters.dateRange.range[1]
        )
          .endOf('day')
          .toDate();
      }

      this.loadTableData();
    },

    /**
     * Remove all filters
     * @param {*} event
     */
    async onRemoveFilters(event) {
      this.tableStateStore.reset();
      await this.loadTableData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      this.tableStateStore.restore(event);
    },
  },
  async created() {
    await this.initTableState(this.auditTableStateConfig);
  },
  async mounted() {
    await this.fetchEnumValues('ResourceType');
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-itemStorages,
.multiselect-employees {
  min-width: 180px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-employees .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
.expansion {
  text-align: left;
  margin-left: 1.5em;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1em;
}
.grid > div {
  padding: 1em 1em;
  border: 2px;
  border: 1px solid $gray-300;
  background: white;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  width: 100%;
  gap: 0.3em;
  margin-bottom: 0.2em;
}

.flex-row > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-word;
}

::v-deep .p-dialog {
  min-width: 500px;
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 75vh;
}
</style>
